/* General container styling */
.coming-soon-container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background:#ffffff;
  font-family: 'Arial', sans-serif;
  overflow: hidden;
}

/* Logo container styling */
.logo-container {
  position: absolute;
  top: 10%;
  z-index: 10;
}

.logo {
  width: 500ps;
  height: 500px;
}

/* Content wrapper */
.coming-soon-content {
  position: relative;
  text-align: center;
  background: rgba(255, 255, 255, 0.15);
  padding: 30px;
  border-radius: 20px;
  backdrop-filter: blur(8px);
  /* box-shadow: 0 8px 30px rgba(0, 0, 0, 0.2); */
  max-width: 500px;
  top: 20%
}

/* Title styling */
.coming-soon-title {
  font-size: 2.5em;
  font-weight: 600;
  color: #000000;
  margin-bottom: 10px;
}

/* Moving dots animation */
/* .moving-dots {
  display: inline-block;
  font-size: 1.5em;
  color: #ffd700;
  position: relative;
  animation: blink 1.5s step-start infinite;
} */

@keyframes blink {
  0%, 20% {
    content: '';
  }
  40% {
    content: '.';
  }
  60% {
    content: '..';
  }
  100% {
    content: '...';
  }
}

/* Subtext styling */
.coming-soon-text {
  font-size: 1.2em;
  color: #000000;
  margin-top: 20px;
}